import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import MainImage from "../images/cafetiere_web_ready_trans.png";
import AltImage from "../images/cafetiere_web_ready_heat_trans.png";
import Arrow from "../images/uparrow.svg";
import "./main.scss";

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" />
    <div id="time-duration-area" className="time-duration-area">
      <div className="input-container">
        <label htmlFor="time-duration-mins">Mins:</label>
        <input
          type="number"
          className="time-input"
          id="time-duration-mins"
          defaultValue="4"
        />
      </div>
      <div className="input-container">
        <label htmlFor="time-duration-secs">Seconds:</label>
        <input
          type="number"
          id="time-duration-secs"
          className="time-input"
          defaultValue="0"
        />
      </div>
      <div className="input-container">
        <label htmlFor="tune">Choose music</label>
        <select id="tune" className="tune">
          {data.wpcontent.mediaItems.nodes.map(node =>
            node.mimeType === "audio/mpeg" ? (
              <option value={node.title} key={node.id}>
                {node.title}
              </option>
            ) : (
              ""
            )
          )}
        </select>
      </div>
    </div>
    <div id="timer-display-area" className="timer-display-area">
      <h1 id="timer-display" className="timer-display">
        0
      </h1>
    </div>
    <h1 id="success-words" className="success-words">
      Coffee Time!!!
    </h1>
    <div id="main-area" className="main-area">
      <div id="main-image-target" className="imagebox">
        <img
          alt="coffee"
          id="main-image"
          src={MainImage}
          className="main-image"
        />
        <img alt="time" id="alt-image" src={AltImage} className="main-image" />
      </div>
      <img id="up-arrow" className="uparrow" src={Arrow} alt="up arrow" />
      <h3 id="instructions" className="instructions">
        Tap the cafetiere to start!
      </h3>
    </div>

    {data.wpcontent.mediaItems.nodes.map(node =>
      node.mimeType === "audio/mpeg" ? (
        <audio
          id={node.title}
          key={node.id}
          src={node.mediaItemUrl}
          type="audio/mpeg"
        ></audio>
      ) : (
        ""
      )
    )}
  </Layout>
);

export const query = graphql`
  query MyQuery {
    wpcontent {
      mediaItems {
        nodes {
          id
          title
          mimeType
          mediaItemUrl
        }
      }
    }
  }
`;

export default IndexPage;
